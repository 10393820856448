import ajax from '@/utils/ajax.js'

/**
 * 导出 订单数据
 * @param {*} params 
 * @returns 
 */
export function exportOrderGoods(params) {
  return ajax.openurl('/ExportDataReport/orderGoods',params)
}

/**
 * 导出 财务下单订单数据
 * @param {*} params 
 * @returns 
 */
export function exportFinancialOrderGoods(params) {
  return ajax.openurl('/FinancialAccountExportDataReport/orderGoods',params)
}

/**
 * 导出 财务交付订单数据（子订单财务）
 * @param {*} params 
 * @returns 
 */
export function exportSubFinancialOrderGoods(params) {
  return ajax.openurl('/FinancialAccountExportDataReport/orderGoods2',params)
}

/**
 * 导出 财务待交付订单数据
 * @param {*} params 
 * @returns 
 */
export function exportFinancialWaitingOrder(params) {
  return ajax.openurl('/FinancialAccountExportDataReport/undeliveredOrder',params)
}

/**
 * 导出 财务交付订单数据-退款（子订单财务）
 * @param {*} params 
 * @returns 
 */
export function exportSubFinancialRefundOrder(params) {
  return ajax.openurl('/FinancialAccountExportDataReport/orderGoods3',params)
}


/**
 * sku应交付数据：每日应交付
 * @param {*} params 
 * @returns 
 */
export function skuDailyDeliver(params) {
  return ajax.post('/DataReport/skuDailyDeliver',params)
}

/**
 * sku销售数据：分城市有效订购量
 * @param {*} params 
 * @returns 
 */
export function skuCitySales(params) {
  return ajax.post('/DataReport/skuSalesRecord',params)
}


