<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="financial_account_id" label="财务账户">
					<a-select style="width:320px;" placeholder="请选择账户" v-model="financial_account_id">
						<a-select-option v-for="(item, index) in financeAccountList"
							:value="item.financial_account_id"
							:key="index">{{item.financial_account_name}}</a-select-option>
					</a-select>
        </a-form-model-item>
        <a-form-model-item prop="search_date" label="日期范围">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <div class="mt-6">
        <a-button style="width:190px;" type="primary" 
        		@click="handlerExport('complete')">导出 交付完成的订单</a-button>
        <span class="btn-tip">* 本财务账户交付完成的订单，按订单交付完成的时间查询</span>
      </div>
      <div class="mt-6">
        <a-button style="width:190px;" type="primary" 
        		@click="handlerExport('refund')">导出 交付后售后的订单</a-button>
        <span class="btn-tip">* 本财务账户交付完成后发生售后的订单，按售后退款的时间查询</span>
      </div>
      <div class="mt-6">
        <a-button style="width:190px;" type="primary" 
        		@click="handlerExport('offline')">导出 小程序线下核销记录</a-button>
        <span class="btn-tip">* 本财务账户下属的门店使用小程序现场核销的记录（消费、退款等），按费用发生时间计算</span>
      </div>
      <div class="mt-6">
        <a-button style="width:190px;" type="primary" 
        		@click="handlerExport('courier')">导出 配送员业绩</a-button>
        <span class="btn-tip">* 本财务账户下属的网点的配送员的配送业绩，按顾客指定的配送时间计算</span>
      </div>

      <div class="mt-6">
        <a-button type="link" class="p-0" @click="handlerExport('all')">全部导出</a-button>
        <span class="btn-tip">（如果没导出，请注意检查是否被浏览器拦截了弹出窗口）</span>
      </div>
    </div>
  </div>
</template>

<script>
import { 
	exportSubFinancialOrderGoods,
	exportSubFinancialRefundOrder,
} from "@/api/finance/analysis.js"
import { 
	financeAccountExportOfflineFlow,
	financeAccountExportCourierAchievement
} from "@/api/finance/finance.js"

export default {
  data() {
    return {
			search_date: [],

			financial_account_id: '',
			financeAccountList: [],
    }
  },
  mounted() {
    this.search_date = [
			this.getToday(),
			this.getToday(),
		]

		try{
			let list = localStorage.getItem('financial_account_list')
					list = JSON.parse(list)
			if(list) {
				this.financeAccountList = list
				this.financial_account_id = list[0].financial_account_id
			}
		}catch(e){
			console.log(e)
		}
  },
  methods: {
    async handlerExport(type){
    	if(!this.financial_account_id){
				this.$message.warning("请选择财务账户")
				return
			}
			if(this.search_date.length < 2){
				this.$message.warning("请选择时间")
				return
			}
			if(new Date(this.search_date[1]).getTime() - new Date(this.search_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超一个月")
					return
				}
			const time_start =  this.search_date[0] + " 00:00:00"
			const time_end   =  this.search_date[1] + " 23:59:59"

			if(type == 'all'){
				this.handlerExport('complete')
				this.handlerExport('refund')
				this.handlerExport('offline')
				this.handlerExport('courier')
				return
			}
    	if(type == "complete"){
    		exportSubFinancialOrderGoods({
    			financial_account_id: this.financial_account_id,
    			finish_time_start: time_start,
    			finish_time_end:   time_end
    		})
    	}
    	if(type == "refund"){
    		exportSubFinancialRefundOrder({
    			financial_account_id: this.financial_account_id,
    			refund_time_start: time_start,
    			refund_time_end:   time_end
    		})
    	}
    	if(type == "offline"){
    		financeAccountExportOfflineFlow({
    			financial_account_id: this.financial_account_id,
					time_start: time_start,
					time_end:   time_end
    		})
    	}
    	if(type == "courier"){
    		financeAccountExportCourierAchievement({
    			financial_account_id: this.financial_account_id,
					time_start: time_start,
					time_end:   time_end
    		})
    	}
    },
    
		getToday(){
			const today = new Date()
			return this.formatDate(today)
		},

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>